<div class="row width-200">
  <!-- tabs card start -->
  <div class="col-sm-12">
   
    
    <app-card moduleName="Job" module="Job" (jobReload)="reload($event)" cardClass="table-card" blockClass="p-0">
      <!-- <perfect-scrollbar [style.max-height]="'415px'"> -->
      <div class="row form-group paddingLeft width-90-percent">
        <div class="ui-g-3 width-10-percent">
          <p-calendar [(ngModel)]="startDate" appendTo="body" class="form-control" dateFormat="dd/mm/yy"
            [showIcon]="true"></p-calendar>
        </div>
        <div class="ui-g-3 width-10-percent">
          <p-calendar [(ngModel)]="endDate" [minDate]="startDate" appendTo="body" class="form-control"
            dateFormat="dd/mm/yy" [showIcon]="true"></p-calendar>
        </div>
        <div class="ui-g-2">
          <button class="btn btn-primary" (click)="searchJobs($event)">
            Search
          </button>
        </div>
        <div class="ui-g-1">
          <button type="button" class="btn button-cls btn-primary color" (click)="exportJob($event)"
            pTooltip="Click to Export" tooltipPosition="top">
            Export
          </button>
          <div class="loader jobExportLoader" *ngIf="showExportLoader"></div>
        </div>
      </div>

      <p-table #dt [value]="jobList" (onPage)="onPageEvent($event)" [(selection)]="selectedJob" dataKey="_id"
        styleClass="ui-table-customers" [rowHover]="true" [rows]="10" [showCurrentPageReport]="true"
        [rowsPerPageOptions]="[10, 25, 50, 100]" [paginator]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [filterDelay]="0"
        [globalFilterFields]="searchArr">
        <ng-template pTemplate="caption">
          <div class="ui-table-globalfilter-container">
            <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
              placeholder="Global Search" />
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th *ngFor="let col of cols" [pSortableColumn]="col.field">
              <b>{{ col.header }}</b>
              <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>

            <!-- <th>Edit</th> -->
            <!-- <th>View</th>
                    <th>Delete</th> -->
            <th></th>
            <th></th>
          </tr>
          <tr>
            <th *ngFor="let col of cols" [ngSwitch]="col.field">
              <input pInputText type="text" class="ui-inputtext searchRowInput"  #searchRowInput
              (input)="dt.filter($event.target.value, col.field, 'contains')" />
            </th>
            <!-- <th></th> -->
            <!-- <th></th> -->
            <th></th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-car>
          <tr *ngIf="!showLoader">
            <td *ngFor="let col of cols" (click)="viewJob($event, car)">
              {{ car[col.field] }}
            </td>

            <td>
              <span (click)="viewJob($event, car)" pTooltip="Click to view a Job" tooltipPosition="top"
                class="mob_icon style-toggler">
                <i class="fa fa-eye fa-2x"></i>
              </span>
              <span (click)="deleteJob(car._id)" pTooltip="Click to delete a Job" tooltipPosition="top"
                class="style-toggler mob_icon delete_icon" *ngIf="checkPermissions('delete', 'Job')">
                <i class="fa fa-trash fa-2x"></i>
              </span>
            </td>

            <td *ngIf="
                isAcumen &&
                isAcumen === 'true' &&
                checkPermissions('edit', 'Job')
              ">
              <!-- <button *ngIf="car.showBigChangeButton && car.jobType === 'Direct Tip'"
                class="btn btn-primary bigchangeBtn1" (click)="getBigChangeNumber(car)">
                Send to bigchange
              </button> -->
              <br>
              <button *ngIf="showMainSystemButton(car)  && car.jobType === 'Direct Tip'"
                class="btn btn-primary bigchangeBtn1" (click)="sendToDsposal(car)">
                Send to Main System
              </button>
              <button *ngIf="showAndHideCompleteJobButton(car)" class="btn btn-primary bigchangeBtn1"
                (click)="patchDsposalJob(car)">
                Complete job
              </button>
            </td>
          </tr>
        </ng-template>
        <ng-template class="noRecords" *ngIf="jobList.length == 0" pTemplate="emptymessage">
          <tr class="noRecords" *ngIf="!showLoader">
            <td [attr.colspan]="cols.length + 1">
              <!-- <div class="my-center-text"> -->
              No Records to display
              <!-- </div> -->
            </td>
          </tr>
        </ng-template>
      </p-table>
      <!-- </perfect-scrollbar> -->
      <div class="loader" *ngIf="showLoader"></div>
    </app-card>
  </div>
  <!-- tabs card end -->
</div>

<div *ngIf="showAdd">
  <app-view-job [data]="jobData" [pageIndex]="pageJobIndex" (closeEvents)="callList($event)"></app-view-job>
</div>