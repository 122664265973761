import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { tap } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class JobService {
  startDate: any;
  endDate: any;
  constructor(private http: HttpClient) {}
  uploadFiles(data) {
    return this.http.post(environment.apiURL + "subject/upload", data).pipe(
      tap((response: any) => {
        return response;
      })
    );
  }
  issues() {
    return this.http.get(environment.apiURL + "shared/list-issues").pipe(
      tap((response: any) => {
        return response;
      })
    );
  }

  jobCount(data?: any) {
    console.log("job request data", data);
    if (data && data.endDate) {
      let tempDate = new Date(data.endDate);
      tempDate.setHours(23, 59, 59, 999);
      data.endDate = tempDate;
    }
    if (data && Object.keys(data).length == 0) {
      return this.http.get(environment.apiURL + "job/jobcount").pipe(
        tap((response: any) => {
          return response;
        })
      );
    } else {
      return this.http.post(environment.apiURL + "job/jobcount", data).pipe(
        tap((response: any) => {
          return response;
        })
      );
    }
  }

  jobList(data?: any) {
    console.log("job request data", data);
    if (data && data.endDate) {
      let tempDate = new Date(data.endDate);
      tempDate.setHours(23, 59, 59, 999);
      data.endDate = tempDate;
    }
    if (data && Object.keys(data).length == 0) {
      return this.http.get(environment.apiURL + "job/list").pipe(
        tap((response: any) => {
          return response;
        })
      );
    } else {
      return this.http.post(environment.apiURL + "job/list", data).pipe(
        tap((response: any) => {
          return response;
        })
      );
    }
  }
  
  jobDetails(id) {
    return this.http.get(environment.apiURL + "job/" + id).pipe(
      tap((response: any) => {
        return response;
      })
    );
  }
  createJob(data) {
    return this.http.post(environment.apiURL + "job/create", data).pipe(
      tap((response: any) => {
        return response;
      })
    );
  }
  exportJobs(data) {
    if (data && data.endDate) {
      let tempDate = new Date(data.endDate);
      tempDate.setHours(23, 59, 59, 999);
      data.endDate = tempDate;
    }
    return this.http.post(environment.apiURL + "job/export", data).pipe(
      tap((response: any) => {
        return response;
      })
    );
  }
  getBigChangeNumber(job) {
    return this.http
      .post(environment.apiURL + "job/get-bigchange-job-number", job)
      .pipe(
        tap((response: any) => {
          return response;
        })
      );
  }
  //uncomment when dsposal is pushed to master
  sendToDsposal(job) {
    return this.http
      .post(environment.apiURL + "job/sendJobToDsposal", job)
      .pipe(
        tap((response: any) => {
          return response;
        })
      );
  }
  patchDsposalJob(job) {
    return this.http
      .post(environment.apiURL + "job/patchDsposalJob", job)
      .pipe(
        tap((response: any) => {
          return response;
        })
      );
  }

  fetchSubject() {
    return this.http.get(environment.apiURL + "subject/list").pipe(
      tap((response: any) => {
        return response;
      })
    );
  }

  createSubject(data) {
    return this.http.post(environment.apiURL + "subject/create", data).pipe(
      tap((response: any) => {
        return response;
      })
    );
  }
  updateSubject(data) {
    return this.http.post(environment.apiURL + "subject/update", data).pipe(
      tap((response: any) => {
        return response;
      })
    );
  }
  deleteSubject(id) {
    return this.http.delete(environment.apiURL + "subject/delete/" + id).pipe(
      tap((response: any) => {
        return response;
      })
    );
  }
  deleteJob(id) {
    return this.http.delete(environment.apiURL + "job/delete/" + id).pipe(
      tap((response: any) => {
        return response;
      })
    );
  }

  setStartAndEndDate(startDate, endDate) {
    this.startDate = startDate;
    this.endDate = endDate;
  }
  getStartAndEndDate() {
    return {
      startDate: this.startDate,
      endDate: this.endDate,
    };
  }
  updateJob(data) {
    return this.http.post(environment.apiURL + "job/update", data).pipe(
      tap((response: any) => {
        return response;
      })
    );
  }

  checkPermissions(type, module) {
    const accessModule = JSON.parse(localStorage.getItem("accessModule"));
    const data = accessModule.filter(
      (item) => item && item.moduleName === module
    );
    switch (type) {
      case "read":
        if (data[0].objectArray.read) {
          return true;
        }
        return false;
      case "create":
        if (data[0].objectArray.create) {
          return true;
        }
        return false;
        break;
      case "edit":
        if (data[0].objectArray.edit) {
          return true;
        }
        return false;
        break;
      case "delete":
        if (data[0].objectArray.delete) {
          return true;
        }
        return false;
        break;
    }
  }
  checkPermissionsToDisableCheckbox(type, module) {
    const accessModule = JSON.parse(localStorage.getItem("accessModule"));
    const data = accessModule.filter(
      (item) => item && item.moduleName === module
    );
    switch (type) {
      case "read":
        if (data[0].objectArray.read) {
          return false;
        }
        return true;
      case "create":
        if (data[0].objectArray.create) {
          return false;
        }
        return true;
      case "edit":
        if (data[0].objectArray.edit) {
          return false;
        }
        return true;
      case "delete":
        if (data[0].objectArray.delete) {
          return false;
        }
        return true;
    }
  }
}
