<div class="row">
    <div class="col-sm-12">
        <app-card moduleName="Messaging" module="Messaging" (jobReload)="reload($event)" (sendMessage)="createMessage($event)" (jobReload)="reload($event)" cardClass="table-card" blockClass="p-0">
            <div class="row form-group paddingLeft width-90-percent">
                <div class="ui-g-3 width-20-percent">
                    <p-calendar [(ngModel)]="startDate" appendTo="body" class="form-control" dateFormat="dd/mm/yy"
                        [showIcon]="true"></p-calendar>
                </div>
                <div class="ui-g-3 width-20-percent">
                    <p-calendar [(ngModel)]="endDate" [minDate]="startDate" appendTo="body" class="form-control"
                      dateFormat="dd/mm/yy" [showIcon]="true"></p-calendar>
                </div>
               
                <!-- <p-dropdown class="card p-0 mt-2" [options]="statusOptions" [(ngModel)]="messageStatus" optionLabel="name" optionValue="value" appendTo="body" autoWidth="false" [style]="{'border': '.5px solid black', 'height': '30px', 'display': 'flex', 'align-items': 'center'}"></p-dropdown> -->
              
                <div class="ui-g-2 flex justify-content-center">
                    <button class="btn btn-primary " (click)="searchMessages($event)">
                      Search
                    </button>
                </div>
            </div>
            <p-table #dt [value]="messageList" (onPage)="onPageEvent($event)" [(selection)]="pageMessageIndex" dataKey="_id"
            styleClass="ui-table-customers" [rowHover]="true" [rows]="10" [showCurrentPageReport]="true"
            [rowsPerPageOptions]="[10, 25, 50, 100]" [paginator]="true"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [filterDelay]="0"
            [globalFilterFields]="searchArr">
                <ng-template pTemplate="caption">
                    <div class="ui-table-globalfilter-container">
                    <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                        placeholder="Global Search" />
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                <tr>
                  <th *ngFor="let col of cols" [pSortableColumn]="col.field">
                    <b>{{ col.header }}</b>
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                  </th>
      
                  <!-- <th>Edit</th> -->
                  <!-- <th>View</th>
                          <th>Delete</th> -->
                  <!-- <th></th>
                  <th></th> -->
                </tr>
                <tr>
                  <th *ngFor="let col of cols" [ngSwitch]="col.field">
                    <input pInputText type="text" class="ui-inputtext searchRowInput" #searchRowInput
                      (input)="dt.filter($event.target.value, col.field, 'contains')" />
                  </th>
                  <!-- <th></th> -->
                  <!-- <th></th> -->
                  
                </tr>
                </ng-template>
                <ng-template pTemplate="body" let-car>
                <tr *ngIf="!showLoader">
                  <td *ngFor="let col of cols" (click)="viewMessage($event, car)">
                    {{ (col.field === 'date_of_dispatch' || col.field === 'modified_at') ? formatDate(car[col.field]) : car[col.field] }}
                  </td>
                  <td>
                    <span (click)="viewMessage($event, car)" pTooltip="Click to view a Message" tooltipPosition="top"
                      class="mob_icon style-toggler">
                      <i class="fa fa-eye fa-2x"></i>
                    </span>
                    <span (click)="deleteMessage(car._id)" pTooltip="Click to delete a Message" tooltipPosition="top"
                      class="style-toggler mob_icon delete_icon" *ngIf="checkPermissions('delete', 'Messaging')">
                      <i class="fa fa-trash fa-2x"></i>
                    </span>
                  </td>
                </tr>
              </ng-template>
              <ng-template class="noRecords" *ngIf="messageList.length === 0" pTemplate="emptymessage">
                <tr class="noRecords" *ngIf="!showLoader">
                  <td [attr.colspan]="cols.length">
                    <!-- <div class="my-center-text"> -->
                    No Records to display
                    <!-- </div> -->
                  </td>
                </tr>
              </ng-template>
            </p-table>
            <div class="loader" *ngIf="showLoader"></div>
        </app-card>
    </div>
</div>
<p-dialog [(visible)]="showSend" [style]="{ width: '100vw', height: '100vh' }" [baseZIndex]="1000" [closable]="false">
  <app-send-edit-message (closeEvents)="closeEvents($event)" (openEvents)="openEvents($event)" [messageStatus]="currentMessageStatus"></app-send-edit-message>
</p-dialog>
