<div class="row">
  <!-- tabs card start -->
  <div class="col-sm-12">
    <app-card cardClass="table-card view-job-details" blockClass="p-0">
      <div class="row">
        <div class="ui-g-11">
          <h1 class="parent">{{ data.job_reference }}</h1>
          <p-button label="Messages" class="chat" (click)="openChat()" *ngIf="!openChatWindow"></p-button>
        </div>
        <div class="ui-g-0">
          <div (click)="closeEvent($event)" >
            <i class="fa fa-window-close fa-2x" pTooltip="Click to close a Job" tooltipPosition="left"></i>
          </div>
        </div>
      </div>
      <div class="row view-job-tab">
        <div class="ui-g-12">
          <p-tabView *ngIf="data.jobType === 'hazardous'">
            <p-tabPanel header="Job Details">
              <div class="container h-100 job-details" *ngIf="!showLoader">
                <div class="row align-items-center h-100">
                  <div class="col-6 mx-auto">
                    <div class="row">
                      <div class="ui-g-6">
                        <span for="userName">Job No.</span>
                      </div>
                      <div class="ui-g-6">
                        <span for="userName">{{ data.job_reference }}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="ui-g-6">
                        <span for="userName">Name of the Employee</span>
                      </div>
                      <div class="ui-g-6">
                        <span for="userName">{{ data.employeeName }}</span>
                      </div>
                    </div>

                    <div class="row">
                      <div class="ui-g-6">
                        <span for="userName">Employee ID</span>
                      </div>
                      <div class="ui-g-6">
                        <span for="userName">{{ data.employeeId }}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="ui-g-6">
                        <span for="userName">Company Name</span>
                      </div>
                      <div class="ui-g-6">
                        <span for="userName">{{ data.company }}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="ui-g-6">
                        <span for="userName">Department</span>
                      </div>
                      <div class="ui-g-6">
                        <span for="userName">{{ data?.department }}</span>
                      </div>
                    </div>

                    <div class="row">
                      <div class="ui-g-6">
                        <span for="userName">Notes</span>
                      </div>
                      <div class="ui-g-6">
                        <span for="userName">{{ data.note_for_location }}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="ui-g-6">
                        <span for="userName">Requested Delivery time</span>
                      </div>
                      <div class="ui-g-6">
                        <span for="userName" *ngIf="data.request_time">{{
                          data.request_time
                          }}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="ui-g-6">
                        <span for="userName">Requested Delivery date</span>
                      </div>
                      <div class="ui-g-6">
                        <span for="userName" *ngIf="data.request_date">{{
                          data.request_date
                          }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="loader" *ngIf="showLoader"></div>
            </p-tabPanel>
            <p-tabPanel header="Map view">
              <agm-map #map [latitude]="locationList[0].lat" [longitude]="locationList[0].long" [zoom]="zoom">
                <agm-marker class="marker-btn" *ngFor="let loc of locationList; let i = index"
                  (markerClick)="clickedMarker(infoWindow)" [label]="loc.label" [iconUrl]="loc.color"
                  [agmFitBounds]="true" [latitude]="loc.lat" [longitude]="loc.long">
                  <agm-info-window #infoWindow [disableAutoPan]="false">
                    <strong>{{ loc.address }}</strong>
                  </agm-info-window>
                </agm-marker>
              </agm-map>
            </p-tabPanel>
            <p-tabPanel header="Line">
              <div class="row">
                <div class="col-12">
                  <p-table #dt2 [value]="lines" [(selection)]="selectedLoc" dataKey="hazardWasteDescription"
                    styleClass="ui-table-customers" [rowHover]="true" [rows]="10" [showCurrentPageReport]="true"
                    [rowsPerPageOptions]="[10, 25, 50, 100]" [paginator]="true"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [filterDelay]="0"
                    [globalFilterFields]="searchArr3">
                    <ng-template pTemplate="caption">
                      <div class="ui-table-globalfilter-container">
                        <input pInputText type="text" (input)="
                            dt2.filterGlobal($event.target.value, 'contains')
                          " placeholder="Global Search" />
                      </div>
                    </ng-template>

                    <ng-template pTemplate="header">
                      <tr>
                        <th style="width: 3em"></th>
                        <th *ngFor="let col of cols3" [pSortableColumn]="col.field">
                          <b>{{ col.header }}</b>
                          <p-sortIcon [field]="col.field"></p-sortIcon>
                        </th>
                      </tr>
                      <tr>
                        <th></th>
                        <th *ngFor="let col of cols3" [ngSwitch]="col.field">
                          <input pInputText type="text" class="ui-inputtext" (input)="
                              dt2.filter(
                                $event.target.value,
                                col.field,
                                'contains'
                              )
                            " />
                        </th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-expanded="expanded" let-columns="cols3" let-car>
                      <tr *ngIf="!showLoader">
                        <td>
                          <a href="#" [pRowToggler]="car">
                            <i [ngClass]="
                                expanded ? 'pi pi-angle-up' : 'pi pi-angle-down'
                              "></i>
                          </a>
                        </td>
                        <td *ngFor="let col of cols3">
                          {{ car[col.field] }}
                        </td>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="rowexpansion" let-car let-columns="cols3">
                      <tr>
                        <td [attr.colspan]="cols3.length + 1">
                          <div class="ui-g ui-fluid" style="font-size: 16px; padding: 20px">
                            <div class="ui-g-12 ui-md-8 w-100">
                              <div class="ui-g">
                                <div class="ui-g-12">
                                  <div class="ui-g-12">
                                    <div class="ui-g-4 ui-element" *ngIf="car.hazardWasteDescription">
                                      <span>
                                        <b> HazardWaste Description: </b>
                                      </span>
                                    </div>
                                    <div class="ui-g-8 ui-element" *ngIf="car.hazardWasteDescription">
                                      {{ car.hazardWasteDescription }}
                                    </div>
                                    <div class="ui-g-4 ui-element" *ngIf="car.quantity">
                                      <span>
                                        <b> Quantity: </b>
                                      </span>
                                    </div>
                                    <div class="ui-g-8 ui-element" *ngIf="car.quantity">
                                      {{ car.quantity }}
                                    </div>

                                    <div class="ui-g-4 ui-element" *ngIf="car.unit">
                                      <span>
                                        <b> Unit of Measure: </b>
                                      </span>
                                    </div>
                                    <div class="ui-g-8 ui-element" *ngIf="car.unit">
                                      {{ car.unit }}
                                    </div>
                                    <div class="ui-g-4 ui-element" *ngIf="car.containerDescription">
                                      <span>
                                        <b> Container storage Description: </b>
                                      </span>
                                    </div>
                                    <div class="ui-g-8 ui-element" *ngIf="car.containerDescription">
                                      {{ car.containerDescription }}
                                    </div>
                                    <div class="ui-g-4 ui-element" *ngIf="car.container_type">
                                      <span>
                                        <b> Container Type: </b>
                                      </span>
                                    </div>
                                    <div class="ui-g-8 ui-element" *ngIf="car.container_type">
                                      {{ car.container_type }}
                                    </div>

                                    <div class="ui-g-4 ui-element" *ngIf="car.waste_type">
                                      <span>
                                        <b> Waste type: </b>
                                      </span>
                                    </div>
                                    <div class="ui-g-8 ui-element" *ngIf="car.waste_type">
                                      {{ car.waste_type }}
                                    </div>
                                    <div class="ui-g-4 ui-element" *ngIf="car.images">
                                      <span>
                                        <b> Images: </b>
                                      </span>
                                    </div>
                                    <div class="ui-g-8 ui-element" *ngIf="car.images">
                                      <div class="ui-g-8 ui-element">
                                        <div class="card-columns paddig-top-img">
                                          <div *ngFor="let i of car.images" class="card">
                                            <div class="ui-g-6 card-img-top" style="cursor: pointer">
                                              <img src="{{ i.url }}" (click)="viewImage(i)" class="image-cls1" />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </ng-template>
                    <ng-template class="noRecords" *ngIf="lines.length == 0" pTemplate="emptymessage">
                      <tr class="noRecords" *ngIf="!showLoader">
                        <td [attr.colspan]="cols3.length + 1">
                          <div class="my-center-text">
                            No Records to display
                          </div>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </div>
                <!-- <div class="loader" *ngIf="showLoader"></div> -->
              </div>
            </p-tabPanel>

            <!-- <p-tabPanel header="Images">
              <div class="ui-g-8 ui-element">

                <div class='card-columns paddig-top-img'>
                  <div *ngFor="let i of data.images" class="card">
                    <div class="ui-g-6 card-img-top" style="cursor: pointer;">
                      <img src="{{i.url}}" (click)="viewImage(i)" class="image-cls1" />
                  </div>
                </div>




                </div>
              </div>
            </p-tabPanel> -->
            <p-tabPanel header="Notes">
              <div class="col-12 mx-auto">
                <form [formGroup]="wasteDescriptionForm">
                  <div class="row">
                    <div class="ui-g-12 mt-3">
                      <p-editor formControlName="description" [style]="{ height: '400px' }">
                      </p-editor>
                    </div>
                  </div>
                  <div class="row" *ngIf="!isUpdate">
                    <div class="ui-g-12">
                      <p-button label="Add" [disabled]="!wasteDescriptionForm.valid"></p-button>
                    </div>
                  </div>
                  <div class="row" *ngIf="isUpdate">
                    <div class="ui-g-12">
                      <p-button label="Update" [disabled]="!wasteDescriptionForm.valid"
                        (onClick)="update($event)"></p-button>
                    </div>
                  </div>
                </form>
              </div>
            </p-tabPanel>
          </p-tabView>
          <p-tabView *ngIf="data.jobType === 'skipsandbins'">
            <p-tabPanel header="Job Details">
              <div class="container h-100 job-details" *ngIf="!showLoader">
                <div class="row align-items-center h-100">
                  <div class="col-6 mx-auto">
                    <div class="row">
                      <div class="ui-g-6">
                        <span for="userName">Job No.</span>
                      </div>
                      <div class="ui-g-6">
                        <span for="userName">{{ data.job_reference }}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="ui-g-6">
                        <span for="userName">Name of the Employee</span>
                      </div>
                      <div class="ui-g-6">
                        <span for="userName">{{ data.employeeName }}</span>
                      </div>
                    </div>

                    <div class="row">
                      <div class="ui-g-6">
                        <span for="userName">Employee ID</span>
                      </div>
                      <div class="ui-g-6">
                        <span for="userName">{{ data.employeeId }}</span>
                      </div>
                    </div>
                    <div class="row" *ngIf="data.action_required">
                      <div class="ui-g-6">
                        <span for="userName">Action Required</span>
                      </div>
                      <div class="ui-g-6">
                        <span for="userName">{{ data.action_required }}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="ui-g-6">
                        <span for="userName">Company Name</span>
                      </div>
                      <div class="ui-g-6">
                        <span for="userName">{{ data.company }}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="ui-g-6">
                        <span for="userName">Department</span>
                      </div>
                      <div class="ui-g-6">
                        <span for="userName">{{ data?.department }}</span>
                      </div>
                    </div>

                    <div class="row">
                      <div class="ui-g-6">
                        <span for="userName">Waste Type</span>
                      </div>
                      <div class="ui-g-6">
                        <span for="userName">{{ data.waste_type }}</span>
                      </div>
                    </div>

                    <div class="row">
                      <div class="ui-g-6">
                        <span for="userName">Container Type</span>
                      </div>
                      <div class="ui-g-6">
                        <span for="userName">{{ data?.container_type }}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="ui-g-6">
                        <span for="userName">Container Description</span>
                      </div>
                      <div class="ui-g-6">
                        <span for="userName">{{
                          data?.container_description
                          }}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="ui-g-6">
                        <span for="userName">Quantity</span>
                      </div>
                      <div class="ui-g-6">
                        <span for="userName">{{ data.quantity }}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="ui-g-6">
                        <span for="userName">Notes</span>
                      </div>
                      <div class="ui-g-6">
                        <span for="userName">{{ data.note_for_location }}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="ui-g-6">
                        <span for="userName">Requested Delivery time</span>
                      </div>
                      <div class="ui-g-6">
                        <span for="userName" *ngIf="data.request_time">{{
                          data.request_time
                          }}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="ui-g-6">
                        <span for="userName">Requested Delivery date</span>
                      </div>
                      <div class="ui-g-6">
                        <span for="userName" *ngIf="data.request_date">{{
                          data.request_date
                          }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="loader" *ngIf="showLoader"></div>
            </p-tabPanel>
            <p-tabPanel header="Map view">
              <agm-map #map [latitude]="locationList[0].lat" [longitude]="locationList[0].long" [zoom]="zoom">
                <agm-marker class="marker-btn" *ngFor="let loc of locationList; let i = index"
                  (markerClick)="clickedMarker(infoWindow)" [label]="loc.label" [iconUrl]="loc.color"
                  [agmFitBounds]="true" [latitude]="loc.lat" [longitude]="loc.long">
                  <agm-info-window #infoWindow [disableAutoPan]="false">
                    <strong>{{ loc.address }}</strong>
                  </agm-info-window>
                </agm-marker>
              </agm-map>
            </p-tabPanel>
            <p-tabPanel header="Images">
              <div class="ui-g-8 ui-element">
                <div class="card-columns paddig-top-img">
                  <div *ngFor="let i of data.images" class="card">
                    <div class="ui-g-6 card-img-top" style="cursor: pointer">
                      <img src="{{ i.url }}" (click)="viewImage(i)" class="image-cls1" />
                    </div>
                  </div>
                </div>
              </div>
            </p-tabPanel>
          </p-tabView>
          <p-tabView *ngIf="data.jobType === 'direct_tip'">
            <p-tabPanel header="Job Details">
              <div class="container h-100 job-details" *ngIf="!showLoader">
                <div class="row align-items-center h-100">
                  <div class="col-6 mx-auto">
                    <div class="row">
                      <div class="ui-g-6">
                        <span for="userName">Job No.</span>
                      </div>
                      <div class="ui-g-6">
                        <span for="userName">{{ data.job_reference }}</span>
                        <span (click)="editJobNumber(data)"
                        class="mob_icon style-toggler delete_icon">
                        <i class="fa fa-edit fa-2x"  pTooltip="Click to edit  Job Number" tooltipPosition="top"></i>
                      </span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="ui-g-6">
                        <span for="userName">Name of the Employee</span>
                      </div>
                      <div class="ui-g-6">
                        <span for="userName">{{ data.employeeName }}</span>
                      </div>
                    </div>

                    <div class="row">
                      <div class="ui-g-6">
                        <span for="userName">Employee ID</span>
                      </div>
                      <div class="ui-g-6">
                        <span for="userName">{{ data.employeeId }}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="ui-g-6">
                        <span for="userName">Company Name</span>
                      </div>
                      <div class="ui-g-6">
                        <span for="userName">{{ data.company }}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="ui-g-6">
                        <span for="userName">Division/Department</span>
                      </div>
                      <div class="ui-g-6">
                        <span for="userName">{{ data?.department }}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="ui-g-6">
                        <span for="userName">Tip Site</span>
                      </div>
                      <div class="ui-g-6">
                        <span for="userName">{{ data.tip_location }}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="ui-g-6">
                        <span for="userName">Disposal Site</span>
                      </div>
                      <div class="ui-g-6">
                        <span for="userName">{{
                          data?.disposeLocationDetails?.Name
                          }}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="ui-g-6">
                        <span for="userName">Disposal Notes</span>
                      </div>
                      <div class="ui-g-6">
                        <span for="userName">{{
                          data?.driverLocationNotes
                          }}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="ui-g-6">
                        <span for="userName">Waste Type</span>
                      </div>
                      <div class="ui-g-6">
                        <span for="userName">{{ data.waste_type }}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="ui-g-6">
                        <span for="userName">Completed</span>
                      </div>
                      <div class="ui-g-6">
                        <span for="userName">{{ data.isCompleted }}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="ui-g-6">
                        <span for="userName">Weighbridge Notes</span>
                      </div>
                      <div class="ui-g-6">
                        <span for="userName">{{ data.free_notes }}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="ui-g-6">
                        <span for="userName">Net Weight</span>
                      </div>
                      <div class="ui-g-6">
                        <span for="userName">{{ data.net_weight }}</span>
                        <span (click)="editnetweight(data)" 
                          class="mob_icon style-toggler delete_icon">
                          <i class="fa fa-edit fa-2x" pTooltip="Click to edit  Net Weight" tooltipPosition="top"></i>
                        </span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="ui-g-6">
                        <span for="userName">Estimated tonnage</span>
                      </div>
                      <div class="ui-g-6">
                        <span for="userName" *ngIf="data.disposeLocationDetails">{{ data.weight }}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="ui-g-6">
                        <span for="userName">Difference Net & Estimated weights</span>
                      </div>
                      <div class="ui-g-6">
                        <span for="userName" *ngIf="data.disposeLocationDetails">{{ differenceinestimatedandactulal
                          }}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="ui-g-6">
                        <span for="userName">Travel Distance from start to final collection</span>
                      </div>
                      <div class="ui-g-6">
                        <span for="userName" *ngIf="data.disposeLocationDetails">{{
                          data.disposeLocationDetails
                          .start2FinalCollectionDistance | number: "1.2-2"
                          }}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="ui-g-6">
                        <span for="userName">Travel Time from start to final collection</span>
                      </div>
                      <div class="ui-g-6">
                        <span for="userName" *ngIf="data.disposeLocationDetails">{{
                          data.disposeLocationDetails
                          .start2FinalCollectionTime
                          }}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="ui-g-6">
                        <span for="userName">CO2 emission from start to final
                          collection(kg/m)</span>
                      </div>
                      <div class="ui-g-6">
                        <span for="userName" *ngIf="data.disposeLocationDetails">{{
                          data.disposeLocationDetails.start2FinalCollectionco2
                          | number: "1.2-2"
                          }}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="ui-g-6">
                        <span for="userName">Travel Distance from final collection to Disposal
                          point</span>
                      </div>
                      <div class="ui-g-6">
                        <span for="userName" *ngIf="data.disposeLocationDetails">{{
                          data.disposeLocationDetails
                          .finalCollection2DisposalDistance
                          | number: "1.2-2"
                          }}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="ui-g-6">
                        <span for="userName">Travel Time from final collection to Disposal
                          point.</span>
                      </div>
                      <div class="ui-g-6">
                        <span for="userName" *ngIf="data.disposeLocationDetails">{{
                          data.disposeLocationDetails
                          .finalCollection2DisposalTime
                          }}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="ui-g-6">
                        <span for="userName">CO2 emission from final collection to Disposal
                          point(kg/m)</span>
                      </div>
                      <div class="ui-g-6">
                        <span for="userName" *ngIf="data.disposeLocationDetails">{{
                          data.disposeLocationDetails
                          .finalCollection2Disposalco2 | number: "1.2-2"
                          }}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="ui-g-6">
                        <span for="userName">Travel Distance from Disposal point to Final
                          location</span>
                      </div>
                      <div class="ui-g-6">
                        <span for="userName" *ngIf="data.disposeLocationDetails">{{
                          data.disposeLocationDetails.disposal2FinalDistance
                          | number: "1.2-2"
                          }}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="ui-g-6">
                        <span for="userName">Travel Time from Disposal point to Final
                          location</span>
                      </div>
                      <div class="ui-g-6">
                        <span for="userName" *ngIf="data.disposeLocationDetails">{{
                          data.disposeLocationDetails.disposal2FinalTime
                          }}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="ui-g-6">
                        <span for="userName">CO2 emission from Disposal point to Final
                          location(kg/m)</span>
                      </div>
                      <div class="ui-g-6">
                        <span for="userName" *ngIf="data.disposeLocationDetails">{{
                          data.disposeLocationDetails.disposal2Finalco2
                          | number: "1.2-2"
                          }}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="ui-g-6">
                        <span for="userName">Total distance</span>
                      </div>
                      <div class="ui-g-6">
                        <span for="userName" *ngIf="data.disposeLocationDetails">{{
                          data.disposeLocationDetails.travelDistance
                          }}</span>
                      </div>
                    </div>
                    <div class="row" *ngIf="isAcumen == 'true'">
                      <div class="ui-g-6">
                        <span for="userName">Total travel Time</span>
                      </div>
                      <div class="ui-g-6">
                        <span for="userName" *ngIf="data.disposeLocationDetails">{{
                          data.disposeLocationDetails.travelTime }}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="ui-g-6">
                        <span for="userName">Total co2 emission rate(kg/m)</span>
                      </div>
                      <div class="ui-g-6">
                        <span for="userName" *ngIf="data.disposeLocationDetails">{{
                          data.disposeLocationDetails.co2 | number: "1.2-2"
                          }}</span>
                      </div>
                    </div>

                    <div class="row" *ngIf="isAcumen == 'true'">
                      <div class="ui-g-6">
                        <span for="userName">PPM for vehicle used</span>
                      </div>
                      <div class="ui-g-6">
                        <span for="userName" *ngIf="data.disposeLocationDetails">{{ data.disposeLocationDetails.ppm
                          }}</span>
                      </div>
                    </div>

                    <div class="row">
                      <div class="ui-g-6">
                        <span for="userName">Vehicle register number</span>
                      </div>
                      <div class="ui-g-6">
                        <span for="userName" *ngIf="data.vehicle_reg_number">{{
                          data.vehicle_reg_number
                          }}</span>
                      </div>
                    </div>

                    <div class="row">
                      <div class="ui-g-6">
                        <span for="userName">Vehicle type used</span>
                      </div>
                      <div class="ui-g-6">
                        <span for="userName" *ngIf="data.disposeLocationDetails">{{
                          data.disposeLocationDetails.vehicleType }}</span>
                      </div>
                    </div>

                    <div class="row" *ngIf="isAcumen == 'true'">
                      <div class="ui-g-6">
                        <span for="userName">Disposal cost</span>
                      </div>
                      <div class="ui-g-6">
                        <span for="userName" *ngIf="data.disposeLocationDetails">{{
                          data.disposeLocationDetails.tonnageCost }}</span>
                      </div>
                    </div>

                    <div class="row" *ngIf="isAcumen == 'true'">
                      <div class="ui-g-6">
                        <span for="userName">Travel cost</span>
                      </div>
                      <div class="ui-g-6">
                        <span for="userName" *ngIf="data.disposeLocationDetails">{{
                          data.disposeLocationDetails.travelCost }}</span>
                      </div>
                    </div>

                    <div class="row">
                      <div class="ui-g-6">
                        <span for="userName">Metal in load </span>
                      </div>
                      <div class="ui-g-6">
                        <span for="userName" *ngIf="data.disposeLocationDetails">{{
                          data.disposeLocationDetails.metal_rebate }}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="ui-g-6">
                        <span for="userName">Estimated weight of metal</span>
                      </div>
                      <div class="ui-g-6">
                        <span for="userName" *ngIf="data.disposeLocationDetails">{{
                          data.disposeLocationDetails.metalRebateCost
                          }}</span>
                      </div>
                    </div>
                    <div class="row" *ngIf="isAcumen == 'true'">
                      <div class="ui-g-6">
                        <span for="userName">Driver cost</span>
                      </div>
                      <div class="ui-g-6">
                        <span for="userName" *ngIf="data.disposeLocationDetails">{{
                          data.disposeLocationDetails.driverCost }}</span>
                      </div>
                    </div>

                    <div class="row" *ngIf="isAcumen == 'true'">
                      <div class="ui-g-6">
                        <span for="userName">Total cost</span>
                      </div>
                      <div class="ui-g-6">
                        <span for="userName" *ngIf="data.disposeLocationDetails">{{
                          data.disposeLocationDetails.totalCost }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="loader" *ngIf="showLoader"></div>
            </p-tabPanel>
            <p-tabPanel header="Weighbridge Certificate">
              <div class="row">
                <div class="col-12">
                  <p-table #dt [value]="imageList" [(selection)]="selectedCerti" dataKey="certificate_img_File"
                    styleClass="ui-table-customers" [rowHover]="true" [rows]="10" [showCurrentPageReport]="true"
                    [rowsPerPageOptions]="[10, 25, 50, 100]" [paginator]="true"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [filterDelay]="0"
                    [globalFilterFields]="searchArr1">
                    <ng-template pTemplate="caption">
                      <div class="ui-table-globalfilter-container">
                        <input pInputText type="text" (input)="
                            dt.filterGlobal($event.target.value, 'contains')
                          " placeholder="Global Search" />
                      </div>
                    </ng-template>

                    <ng-template pTemplate="header">
                      <tr>
                        <th style="width: 3em"></th>
                        <th *ngFor="let col of cols1" [pSortableColumn]="col.field">
                          <b>{{ col.header }}</b>
                          <p-sortIcon [field]="col.field"></p-sortIcon>
                        </th>

                        <!-- <th>Edit</th> -->
                        <!-- <th>View</th> -->
                        <!-- <th>Delete</th> -->
                      </tr>
                      <tr>
                        <th></th>
                        <th *ngFor="let col of cols1" [ngSwitch]="col.field">
                          <input pInputText type="text" class="ui-inputtext" (input)="
                              dt.filter(
                                $event.target.value,
                                col.field,
                                'contains'
                              )
                            " />
                        </th>
                        <!-- <th></th> -->
                        <!-- <th></th> -->
                        <!-- <th></th> -->
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-expanded="expanded" let-columns="cols1" let-car1>
                      <tr *ngIf="!showLoader" [pSelectableRow]="car1" [pRowToggler]="car1">
                        <td>
                          <a href="#">
                            <i [ngClass]="
                                expanded ? 'pi pi-angle-up' : 'pi pi-angle-down'
                              "></i>
                          </a>
                        </td>

                        <td *ngFor="let col of cols1">
                          Certificate Image
                        </td>
                        <!-- <td *ngFor="let col of cols1">
                          {{ car1[col.field] }}
                        </td> -->

                        <!-- <td>
                                  <span (click)='editSubject($event,car)'>
                                    <i class='fa fa-pencil fa-2x'></i>
                                  </span>
                                </td> -->
                        <!-- <td>
                                  <span (click)='viewSubject($event,car)'>
                                    <i class='fa fa-eye fa-2x'></i>
                                  </span>
                                </td> -->
                        <td *ngIf="car1.delete">
                          <span (click)="deleteJob(car1._id)">
                            <i class="fa fa-trash fa-2x"></i>
                          </span>
                        </td>
                        <!-- <td *ngIf="!car.delete">
                                  <span >
                                    -
                                  </span>
                                </td> -->
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="rowexpansion" let-car1 let-columns="cols1">
                      <tr>
                        <td [attr.colspan]="cols1.length + 1">
                          <div class="ui-g ui-fluid" style="font-size: 16px; padding: 20px">
                            <div class="ui-g-12 ui-md-8">
                              <div class="ui-g">
                                <div class="ui-g-12">
                                  <div class="ui-g-6 ui-element">
                                    <span><b>Images :</b> </span>
                                  </div>
                                  <div class="ui-g-6 ui-element">
                                    <div>
                                      <img src="{{ car1.certificate_img }}" class="image-cls"
                                        (click)="viewCertificate(car1)" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </ng-template>
                    <ng-template class="noRecords" *ngIf="imageList.length == 0" pTemplate="emptymessage">
                      <tr class="noRecords" *ngIf="!showLoader">
                        <td [attr.colspan]="cols1.length + 1">
                          <div class="my-center-text">
                            No Records to display
                          </div>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </div>
                <div class="loader" *ngIf="showLoader"></div>
              </div>
            </p-tabPanel>
            <p-tabPanel header="Interim location">
              <div class="row">
                <div class="col-12">
                  <p-table #dt1 [value]="interimLocationList" [(selection)]="selectedLoc" dataKey="address"
                    styleClass="ui-table-customers" [rowHover]="true" [rows]="10" [showCurrentPageReport]="true"
                    [rowsPerPageOptions]="[10, 25, 50, 100]" [paginator]="true"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [filterDelay]="0"
                    [globalFilterFields]="searchArr">
                    <ng-template pTemplate="caption">
                      <div class="ui-table-globalfilter-container">
                        <input pInputText type="text" (input)="
                            dt1.filterGlobal($event.target.value, 'contains')
                          " placeholder="Global Search" />
                      </div>
                    </ng-template>

                    <ng-template pTemplate="header">
                      <tr>
                        <th style="width: 3em"></th>
                        <th *ngFor="let col of cols" [pSortableColumn]="col.field">
                          <b>{{ col.header }}</b>
                          <p-sortIcon [field]="col.field"></p-sortIcon>
                        </th>
                      </tr>
                      <tr>
                        <th></th>
                        <th *ngFor="let col of cols" [ngSwitch]="col.field">
                          <input pInputText type="text" class="ui-inputtext" (input)="
                              dt.filter(
                                $event.target.value,
                                col.field,
                                'contains'
                              )
                            " />
                        </th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-expanded="expanded" let-columns="cols" let-car>
                      <tr *ngIf="!showLoader" [pSelectableRow]="car" [pRowToggler]="car">
                        <td>
                          <a href="#">
                            <i [ngClass]="
                                expanded ? 'pi pi-angle-up' : 'pi pi-angle-down'
                              "></i>
                          </a>
                        </td>
                        <td *ngFor="let col of cols">
                          {{ car[col.field] }}
                        </td>

                        <td *ngIf="car.delete">
                          <span (click)="deleteJob(car._id)">
                            <i class="fa fa-trash fa-2x"></i>
                          </span>
                        </td>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="rowexpansion" let-car let-columns="cols">
                      <tr>
                        <td [attr.colspan]="cols.length + 1">
                          <div class="ui-g ui-fluid" style="font-size: 16px; padding: 20px">
                            <div class="ui-g-12 ui-md-8 w-100">
                              <div class="ui-g">
                                <div class="ui-g-12">
                                  <div class="ui-g-4 ui-element">
                                    <span><b>Images :</b> </span>
                                  </div>
                                  <div class="ui-g-8 ui-element">
                                    <div *ngFor="let i of car.images">
                                      <img src="{{ i.url }}" (click)="viewImage(i)" class="image-cls" />
                                    </div>
                                  </div>
                                </div>
                                <div class="ui-g-12">
                                  <div class="ui-g-4 ui-element">
                                    <span>
                                      <b> Works Order reference: </b>
                                    </span>
                                  </div>
                                  <div class="ui-g-8 ui-element">
                                    {{ car.code }}
                                  </div>
                                </div>
                                <div class="ui-g-12">
                                  <div class="ui-g-4 ui-element">
                                    <span>
                                      <b> Notes: </b>
                                    </span>
                                  </div>
                                  <div class="ui-g-8 ui-element">
                                    {{ car.note }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </ng-template>
                    <ng-template class="noRecords" *ngIf="interimLocationList.length == 0" pTemplate="emptymessage">
                      <tr class="noRecords" *ngIf="!showLoader">
                        <td [attr.colspan]="cols.length + 1">
                          <div class="my-center-text">
                            No Records to display
                          </div>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </div>
                <div class="loader" *ngIf="showLoader"></div>
              </div>
            </p-tabPanel>

            <p-tabPanel header="Map view">
              <div class="row">
                <div class="ui-g-12 ui-float-label mt-3">
                  <p-checkbox [formControl]="JobForm.controls['pickup_location']" class="map-checkbox first-checkbox"
                    (onChange)="change($event, 'pickup_location')" label="Start location" binary="true"></p-checkbox>
                  <p-checkbox [formControl]="JobForm.controls['next_location']" class="map-checkbox second-checkbox"
                    (onChange)="change($event, 'next_location')" label="Final Destination" binary="true"></p-checkbox>
                  <p-checkbox [formControl]="JobForm.controls['disposal_location']" class="map-checkbox third-checkbox"
                    (onChange)="change($event, 'disposal_location')" label="Disposal location" binary="true">
                  </p-checkbox>
                  <p-checkbox [formControl]="JobForm.controls['interim_location']" class="map-checkbox fourth-checkbox"
                    (onChange)="change($event, 'interim_location')" label="Interim locations"
                    binary="true"></p-checkbox>
                  <p-checkbox [formControl]="
                      JobForm.controls['suggested_disposal_location']
                    " class="map-checkbox fifth-checkbox" (onChange)="change($event, 'suggested_disposal_location')"
                    label="Suggested Disposal Locations" binary="true"></p-checkbox>
                  <p-checkbox [formControl]="
                      JobForm.controls['final_collection_loaction']
                    " class="map-checkbox sixth-checkbox" (onChange)="change($event, 'final_collection_loaction')"
                    label="Final collection Location" binary="true"></p-checkbox>
                </div>
              </div>
              <agm-map #map [latitude]="locationList[0].lat" [longitude]="locationList[0].long" [zoom]="zoom">
                <agm-marker class="marker-btn" *ngFor="let loc of locationList; let i = index"
                  (markerClick)="clickedMarker(infoWindow)" [label]="loc.label" [iconUrl]="loc.color"
                  [agmFitBounds]="true" [latitude]="loc.lat" [longitude]="loc.long">
                  <agm-info-window #infoWindow [disableAutoPan]="false">
                    <strong>{{ loc.address }}</strong>
                  </agm-info-window>
                </agm-marker>
              </agm-map>
            </p-tabPanel>
            <p-tabPanel header="Last waste location">
              <div class="row">
                <div class="col-12">
                  <p-table #dt2 [value]="lastwasteLocation" [(selection)]="selectedLoc" dataKey="address"
                    styleClass="ui-table-customers" [rowHover]="true" [rows]="10" [showCurrentPageReport]="true"
                    [rowsPerPageOptions]="[10, 25, 50, 100]" [paginator]="true"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [filterDelay]="0"
                    [globalFilterFields]="searchArr2">
                    <ng-template pTemplate="caption">
                      <div class="ui-table-globalfilter-container">
                        <input pInputText type="text" (input)="
                            dt2.filterGlobal($event.target.value, 'contains')
                          " placeholder="Global Search" />
                      </div>
                    </ng-template>

                    <ng-template pTemplate="header">
                      <tr>
                        <th style="width: 3em"></th>
                        <th *ngFor="let col of cols2" [pSortableColumn]="col.field">
                          <b>{{ col.header }}</b>
                          <p-sortIcon [field]="col.field"></p-sortIcon>
                        </th>
                      </tr>
                      <tr>
                        <th></th>
                        <th *ngFor="let col of cols2" [ngSwitch]="col.field">
                          <input pInputText type="text" class="ui-inputtext" (input)="
                              dt.filter(
                                $event.target.value,
                                col.field,
                                'contains'
                              )
                            " />
                        </th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-expanded="expanded" let-columns="cols" let-car>
                      <tr *ngIf="!showLoader" [pSelectableRow]="car" [pRowToggler]="car">
                        <td>
                          <a href="#">
                            <i [ngClass]="
                                expanded ? 'pi pi-angle-up' : 'pi pi-angle-down'
                              "></i>
                          </a>
                        </td>
                        <td *ngFor="let col of cols2">
                          {{ car[col.field] }}
                        </td>

                        <td *ngIf="car.delete">
                          <span (click)="deleteJob(car._id)">
                            <i class="fa fa-trash fa-2x"></i>
                          </span>
                        </td>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="rowexpansion" let-car let-columns="cols">
                      <tr>
                        <td [attr.colspan]="cols2.length + 1">
                          <div class="ui-g ui-fluid" style="font-size: 16px; padding: 20px">
                            <div class="ui-g-12 ui-md-8 w-100">
                              <div class="ui-g">
                                <div class="ui-g-12">
                                  <div class="ui-g-4 ui-element">
                                    <span><b>Images :</b> </span>
                                  </div>
                                  <div class="ui-g-8 ui-element">
                                    <div *ngIf="car.image">
                                      <img src="{{ car.image }}" (click)="viewImage(car.image)" class="image-cls" />
                                    </div>
                                    <div *ngIf="!car.image">
                                      <!-- <img src="{{car.images[0].url}}" (click)="viewImage(car.images[0].url)" class="image-cls" /> -->
                                      <div *ngFor="let i of car.images">
                                        <img src="{{ i.url }}" (click)="viewImage(i)" class="image-cls" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="ui-g-12">
                                  <div class="ui-g-4 ui-element">
                                    <span>
                                      <b> Works Order reference: </b>
                                    </span>
                                  </div>
                                  <div class="ui-g-8 ui-element">
                                    {{ car.code }}
                                    <!-- car.image -->
                                  </div>
                                </div>
                                <div class="ui-g-12">
                                  <div class="ui-g-4 ui-element">
                                    <span>
                                      <b> Notes: </b>
                                    </span>
                                  </div>
                                  <div class="ui-g-8 ui-element">
                                    {{ car.note }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </ng-template>
                    <ng-template class="noRecords" *ngIf="interimLocationList.length == 0" pTemplate="emptymessage">
                      <tr class="noRecords" *ngIf="!showLoader">
                        <td [attr.colspan]="cols.length + 1">
                          <div class="my-center-text">
                            No Records to display
                          </div>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </div>
                <!-- <div class="loader" *ngIf="showLoader"></div> -->
              </div>
            </p-tabPanel>
            <p-tabPanel header="Error Messages">
              <div class="row">
                <div class="col-12">
                  <p-table #dt3 [value]="errorMessageData" styleClass="ui-table-customers" [rowHover]="true" [rows]="10"
                    [showCurrentPageReport]="true" [rowsPerPageOptions]="[10, 25, 50, 100]" [paginator]="true"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [filterDelay]="0"
                    [globalFilterFields]="searchErrorMessage" datakey="message">
                    <ng-template pTemplate="caption">
                      <div class="ui-table-globalfilter-container">
                        <input pInputText type="text" (input)="
                        dt3.filterGlobal($event.target.value, 'contains')
                          " placeholder="Global Search" />
                      </div>
                    </ng-template>

                    <ng-template pTemplate="header">
                      <tr>
                        <th style="width: 3em"></th>
                        <th *ngFor="let col of errorMessageColumn" [pSortableColumn]="col.field">
                          <b>{{ col.header }}</b>
                          <p-sortIcon [field]="col.field"></p-sortIcon>
                        </th>
                      </tr>
                      <tr>
                        <th></th>
                        <th *ngFor="let col of errorMessageColumn" [ngSwitch]="col.field">
                          <input pInputText type="text" class="ui-inputtext" (input)="
                              dt3.filter(
                                $event.target.value,
                                col.field,
                                'contains'
                              )
                            " />
                        </th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-columns="cols" let-car>
                      <tr *ngIf="!showLoader" [pSelectableRow]="car" [pRowToggler]="car">
                        <td>
                          <a href="#">
                            <!-- <i [ngClass]="
                                expanded ? 'pi pi-angle-up' : 'pi pi-angle-down'
                              "></i> -->
                          </a>
                        </td>
                        <td *ngFor="let col of errorMessageColumn">
                          {{ col.field === 'date' ? (car[col.field] | date: 'dd/MM/yyyy h:mm a') : car[col.field] }}
                        </td>

                        <!-- <td *ngIf="car.delete">
                          <span (click)="deleteJob(car._id)">
                            <i class="fa fa-trash fa-2x"></i>
                          </span>
                        </td> -->
                      </tr>
                    </ng-template>
                    <ng-template class="noRecords" *ngIf="errorMessageData.length == 0" pTemplate="emptymessage">
                      <tr class="noRecords" *ngIf="!showLoader">
                        <td [attr.colspan]="errorMessageColumn.length + 1">
                          <div class="my-center-text">
                            No Records to display
                          </div>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </div>
                <!-- <div class="loader" *ngIf="showLoader"></div> -->
              </div>
            </p-tabPanel>
          </p-tabView>
          <!-- <div class="loader" *ngIf="showLoader"></div> -->
        </div>
        <div class="loader" *ngIf="showLoader"></div>
      </div>
    </app-card>
  </div>
</div>
<div *ngIf="editweight">
  <app-add-edit-netweight [isView]="isView" [data]="jobdata" (closeEvents)="callList($event)"></app-add-edit-netweight>
</div>

<div *ngIf="editjobnumber">
  <app-add-edit-job-number [isView]="isView" [data]="jobdata" (closeEvents)="callList($event)"></app-add-edit-job-number>
</div>

<div *ngIf="openChatWindow">
  <app-chat [data]="data" (closeEvents)="closeChatWindow($event)"> </app-chat>
</div>