import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AdminComponent } from "./theme/layout/admin/admin.component";
import { AuthComponent } from "./theme/layout/auth/auth.component";
import { NavGuardService } from "./nav-guard.service";
import { LoginComponent } from "./login/login.component";
import { HazardousComponent } from "./demo/hazardous/hazardous.component";
import { SkipComponent } from "./demo/skip/skip.component";
import { ViewJobDetailsComponent } from "./demo/job/view-job-details/view-job-details.component";
import { WasteDescriptionComponent } from "./demo/waste-description/waste-description.component";
import { ViewReportsComponent } from "./demo/reports/view-reports/view-reports.component";
import { ViewInspectionReportComponent } from "./demo/inspection-report/view-inspection-report/view-inspection-report.component";
import { ListCategoryComponent } from "./demo/waste-category/list-category/list-category.component";
import { ListEWCCodeComponent } from "./demo/disposal-sites/list-ewc-code/list-ewc-code.component";
import { ListPriceComponent } from "./demo/disposal-sites/list-price/list-price.component";
import { UserPermissionComponent } from "./demo/admin-users/user-permission/user-permission.component";
import { AdminGuard } from "./guards/admin.guard";
import { JobGuard } from "./guards/job.guard";
import { MessagingGuard } from "./guards/messaging.guard";
import { ViewMessageDetailsComponent } from "./demo/messaging/view-message-details/view-message-details.component";
import { CustomersGuard } from "./guards/customers.guard";
import { CustomerDivisionGuard } from "./guards/customer-division.guard";
import { WasteCategoryGuard } from "./guards/waste-category.guard";
import { WasteDescriptionGuard } from "./guards/waste-description.guard";
import { HazardousGuard } from "./guards/hazardous.guard";
import { SkipsAndBinsGuard } from "./guards/skips-and-bins.guard";
import { DisposalSitesGuard } from "./guards/disposal-sites.guard";
import { VehicleTypesGuard } from "./guards/vehicle-types.guard";
import { StaffGuard } from "./guards/staff.guard";
import { WasteReportsGuard } from "./guards/waste-reports.guard";
import { InspectionReportsGuard } from "./guards/inspection-reports.guard";
import { FormQuestionGuard } from "./guards/form-question.guard";
import { FormSectionGuard } from "./guards/form-section.guard";
import { FormBuilderGuard } from "./guards/form-builder.guard";
import { FormAccessComponent } from "./demo/form-builders/form-access/form-access.component";
import { ViewNewInspectionReportComponent } from "./demo/new-inspection-report/view-inspection-report/view-new-inspection-report.component";
import { ArchivedReportsGuard } from "./guards/archived-reports.guard";
import { JobDataComponent } from "./demo/job/job-data/job-data.component";
const routes: Routes = [
  {
    path: "",
    component: AdminComponent,
    children: [
      {
        path: "",
        redirectTo: "/dashboard/analytics",
        pathMatch: "full",
      },
      {
        path: "dashboard",
        loadChildren: () =>
          import("./demo/dashboard/dashboard.module").then(
            (module) => module.DashboardModule
          ),
        
          
      },
      {
        path: "vehicle",
        loadChildren: () =>
          import("./demo/vehicle/vehicle.module").then(
            (module) => module.VehicleModule
          ),
      },
      {
        path: "job",
       
        loadChildren: () =>
          import("./demo/job/job.module").then((module) => module.JobModule),
        canActivate: [JobGuard],
      },
      {
        path: "job/data/:id",
        component: ViewJobDetailsComponent,
        canActivate: [JobGuard],
      },
      {
        path: "messages",
        loadChildren: () => 
          import("./demo/messaging/messaging.module").then((module) => module.MessagingModule),
          canActivate: [MessagingGuard],
      },
      {
        path: "messages/data/:id",
        component: ViewMessageDetailsComponent,
        canActivate: [MessagingGuard],
      },
      {
        path: "admin/users",
        loadChildren: () =>
          import("./demo/admin-users/admin-users.component.module").then(
            (module) => module.AdminUserModule
          ),
        canActivate: [AdminGuard],
      },
      {
        path: "form-questions",
        loadChildren: () =>
          import("./demo/form-questions/form-questions.component.module").then(
            (module) => module.FormQuestionsModule
          ),
        canActivate: [FormQuestionGuard],
      },
      {
        path: "form-sections",
        loadChildren: () =>
          import("./demo/form-sections/form-sections.component.module").then(
            (module) => module.FormSectionsModule
          ),
        canActivate: [FormSectionGuard],
      },
      {
        path: "form-builders",
        loadChildren: () =>
          import("./demo/form-builders/form-builders.component.module").then(
            (module) => module.FormBuildersModule
          ),
        canActivate: [FormBuilderGuard],
      },
      {
        path: "form-type",
        loadChildren: () =>
          import("./demo/formType/form-type.component.module").then(
            (module) => module.FormTypeModule
          ),
      },
      {
        path: "wastecategory",
        loadChildren: () =>
          import("./demo/waste-category/waste-category.module").then(
            (module) => module.WasteCategoryModule
          ),
        canActivate: [WasteCategoryGuard],
      },
      {
        path: "waste-description",
        component: WasteDescriptionComponent,
        canActivate: [WasteDescriptionGuard],
      },
      {
        path: "Hazardous",
        component: HazardousComponent,
        canActivate: [HazardousGuard],
      },
      {
        path: "skip",
        component: SkipComponent,
        canActivate: [SkipsAndBinsGuard],
      },
      {
        path: "customers",
        loadChildren: () =>
          import("./demo/customers/customers.module").then(
            (module) => module.CustomersModule
          ),
        canActivate: [CustomersGuard],
      },
      {
        path: "customerdivisions",
        loadChildren: () =>
          import("./demo/customer-divisions/customer-divisions.module").then(
            (module) => module.CustomerDivisionsModule
          ),
        canActivate: [CustomerDivisionGuard],
      },

      {
        path: "disposalsites",
        loadChildren: () =>
          import("./demo/disposal-sites/disposal-sites.module").then(
            (module) => module.DisposalSitesModule
          ),
        canActivate: [DisposalSitesGuard],
      },
      {
        path: "customer-sites",
        loadChildren: () =>
          import("./demo/customer-site/customer-site.module").then(
            (module) => module.CustomerSiteModule
          ),
      },
      {
        path: "reports",
        loadChildren: () =>
          import("./demo/reports/reports.module").then(
            (module) => module.ReportsModule
          ),
        canActivate: [WasteReportsGuard],
      },
      {
        path: "inspectionReport",
        loadChildren: () =>
          import("./demo/inspection-report/inspection-report.module").then(
            (module) => module.InspectionReportModule
          ),
        canActivate: [ArchivedReportsGuard],
      },
      {
        path: "new-inspection-report",
        loadChildren: () =>
          import(
            "./demo/new-inspection-report/new-inspection-report.module"
          ).then((module) => module.NewInspectionReportModule),
        canActivate: [InspectionReportsGuard],
      },
      {
        path: "inspectionReport/:id",
        component: ViewInspectionReportComponent,
        canActivate: [ArchivedReportsGuard],
      },
      {
        path: "new-inspection-report/:id",
        component: ViewNewInspectionReportComponent,
      },
      {
        path: "user-permission/:id",
        component: UserPermissionComponent,
        canActivate: [AdminGuard],
      },
      {
        path: "form-buildfer-access",
        component: FormAccessComponent,
        canActivate: [AdminGuard],
      },
      {
        path: "reports/:id",
        component: ViewReportsComponent,
        canActivate: [WasteReportsGuard],
      },

      {
        path: "disposalsites/listcategory/:id",
        component: ListEWCCodeComponent,
        canActivate: [DisposalSitesGuard],
      },

      {
        path: "disposalsites/listPrices/:id",
        component: ListPriceComponent,
        canActivate: [DisposalSitesGuard],
      },
      {
        path: "wastecategory/listcategory/:id",
        component: ListCategoryComponent,
        canActivate: [WasteCategoryGuard],
      },
      {
        path: "vehicletypes",
        loadChildren: () =>
          import("./demo/vehicle-types/vehicle-types.module").then(
            (module) => module.VehicleTypesModule
          ),
        canActivate: [VehicleTypesGuard],
      },
      {
        path: "staff",
        loadChildren: () =>
          import("./demo/staff/staff.module").then(
            (module) => module.StaffModule
          ),
        canActivate: [StaffGuard],
      },
      {
        path: "layout",
        loadChildren: () =>
          import("./demo/pages/layout/layout.module").then(
            (module) => module.LayoutModule
          ),
      },
      {
        path: "widget",
        loadChildren: () =>
          import("./demo/widget/widget.module").then(
            (module) => module.WidgetModule
          ),
      },
      {
        path: "users",
        loadChildren: () =>
          import("./demo/users/users.module").then(
            (module) => module.UsersModule
          ),
      },
      {
        path: "basic",
        loadChildren: () =>
          import("./demo/ui-elements/ui-basic/ui-basic.module").then(
            (module) => module.UiBasicModule
          ),
      },
      {
        path: "advance",
        loadChildren: () =>
          import("./demo/ui-elements/ui-adv/ui-adv.module").then(
            (module) => module.UiAdvModule
          ),
      },
      {
        path: "forms",
        loadChildren: () =>
          import("./demo/pages/form-elements/form-elements.module").then(
            (module) => module.FormElementsModule
          ),
      },
      {
        path: "tbl-bootstrap",
        loadChildren: () =>
          import("./demo/pages/tables/tbl-bootstrap/tbl-bootstrap.module").then(
            (module) => module.TblBootstrapModule
          ),
      },
      {
        path: "tbl-datatable",
        loadChildren: () =>
          import("./demo/pages/tables/tbl-datatable/tbl-datatable.module").then(
            (module) => module.TblDatatableModule
          ),
      },
      {
        path: "charts",
        loadChildren: () =>
          import("./demo/pages/core-chart/core-chart.module").then(
            (module) => module.CoreChartModule
          ),
      },
      {
        path: "maps",
        loadChildren: () =>
          import("./demo/pages/core-maps/core-maps.module").then(
            (module) => module.CoreMapsModule
          ),
      },
      {
        path: "email",
        loadChildren: () =>
          import("./demo/application/email/email.module").then(
            (module) => module.EmailModule
          ),
      },
      {
        path: "task",
        
        loadChildren: () =>
          import("./demo/application/task/task.module").then(
            (module) => module.TaskModule
          ),
      },
      {
        path: "todo",
        loadChildren: () =>
          import("./demo/application/todo/todo.module").then(
            (module) => module.TodoModule
          ),
      },
      {
        path: "gallery",
        loadChildren: () =>
          import("./demo/application/gallery/gallery.module").then(
            (module) => module.GalleryModule
          ),
      },
      {
        path: "helpdesk",
        loadChildren: () =>
          import("./demo/application/helpdesk/helpdesk.module").then(
            (module) => module.HelpdeskModule
          ),
      },
      {
        path: "editor",
        loadChildren: () =>
          import("./demo/extension/editor/editor.module").then(
            (module) => module.EditorModule
          ),
      },
      {
        path: "invoice",
        loadChildren: () =>
          import("./demo/extension/invoice/invoice.module").then(
            (module) => module.InvoiceModule
          ),
      },
      {
        path: "full-calendar",
        loadChildren: () =>
          import(
            "./demo/extension/full-event-calendar/full-event-calendar.module"
          ).then((module) => module.FullEventCalendarModule),
      },
      {
        path: "file-upload",
        loadChildren: () =>
          import("./demo/extension/files-upload/files-upload.module").then(
            (module) => module.FilesUploadModule
          ),
      },
      {
        path: "sample-page",
        loadChildren: () =>
          import("./demo/pages/sample-page/sample-page.module").then(
            (module) => module.SamplePageModule
          ),
      },
    ],
    canActivateChild: [NavGuardService],
  },
  {
    path: "",
    children: [
      {
        path: "auth",
        loadChildren: () =>
          import("./demo/pages/authentication/authentication.module").then(
            (module) => module.AuthenticationModule
          ),
      },
      {
        path: "maintenance",
        loadChildren: () =>
          import("./demo/pages/maintenance/maintenance.module").then(
            (module) => module.MaintenanceModule
          ),
      },
      { path: "**", component: LoginComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
