import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class JobGuard implements CanActivate {
  constructor(private router: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const menuList = JSON.parse(localStorage.getItem("accessModule"));
    const adminModule = menuList.find(
      (item) => item && item.moduleName === "Job"
    );
   

    if (adminModule?.objectArray?.read) {
      return true;
    }
    if (localStorage.getItem("token")) {
      history.back();
    }
    return this.router.navigate(["/login"]);
  }
}
